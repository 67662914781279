import createReactComponent from '../createReactComponent';
export default createReactComponent('meter-cube', 'IconMeterCube', [
  [
    'path',
    {
      d: 'M17 5h1.5a1.5 1.5 0 0 1 0 3h-.5h.5a1.5 1.5 0 0 1 0 3h-1.5',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M4 12v6', key: 'svg-1' }],
  [
    'path',
    { d: 'M4 14a2 2 0 0 1 2 -2h.5a2.5 2.5 0 0 1 2.5 2.5v3.5', key: 'svg-2' },
  ],
  ['path', { d: 'M9 15.5v-1a2.5 2.5 0 1 1 5 0v3.5', key: 'svg-3' }],
]);
