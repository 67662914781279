import createReactComponent from '../createReactComponent';
export default createReactComponent('navigation-south', 'IconNavigationSouth', [
  [
    'path',
    {
      d: 'M10 8.25c0 .414 .336 .75 .75 .75h2.25a1 1 0 0 0 1 -1v-1a1 1 0 0 0 -1 -1h-2a1 1 0 0 1 -1 -1v-1a1 1 0 0 1 1 -1h2.25a.75 .75 0 0 1 .75 .75',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M16 21l-4 -8l-4 8l4 -2z', key: 'svg-1' }],
]);
