import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'bookmark-question',
  'IconBookmarkQuestion',
  [
    [
      'path',
      {
        d: 'M15 19l-3 -2l-6 4v-14a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v4',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M19 22v.01', key: 'svg-1' }],
    [
      'path',
      {
        d: 'M19 19a2.003 2.003 0 0 0 .914 -3.782a1.98 1.98 0 0 0 -2.414 .483',
        key: 'svg-2',
      },
    ],
  ],
);
