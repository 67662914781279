import createReactComponent from '../createReactComponent';
export default createReactComponent('calendar-sad', 'IconCalendarSad', [
  [
    'path',
    {
      d: 'M4 7a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2v-12zm12 -4v4m-8 -4v4m-4 4h16m-9.995 3h.01m3.99 0h.01',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M10 18a3.5 3.5 0 0 1 4 0', key: 'svg-1' }],
]);
