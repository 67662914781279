import createReactComponent from '../createReactComponent';
export default createReactComponent('letter-q-small', 'IconLetterQSmall', [
  [
    'path',
    {
      d: 'M12 8a2 2 0 0 1 2 2v4a2 2 0 1 1 -4 0v-4a2 2 0 0 1 2 -2z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M13 15l1 1', key: 'svg-1' }],
]);
