import createReactComponent from '../createReactComponent';
export default createReactComponent('hexagon-minus-2', 'IconHexagonMinus2', [
  [
    'path',
    {
      d: 'M13.092 21.72a2.269 2.269 0 0 1 -2.184 0l-6.75 -4.27a2.225 2.225 0 0 1 -1.158 -1.948v-7.285c0 -.809 .443 -1.554 1.158 -1.947l6.75 -3.98a2.33 2.33 0 0 1 2.25 0l6.75 3.98h-.033c.7 .398 1.13 1.143 1.125 1.948v6.782',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M16 19h6', key: 'svg-1' }],
]);
