import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'square-rounded-plus-2',
  'IconSquareRoundedPlus2',
  [
    [
      'path',
      {
        d: 'M12.54 20.996c-.176 .004 -.356 .004 -.54 .004c-7.2 0 -9 -1.8 -9 -9s1.8 -9 9 -9s9 1.8 9 9c0 .185 -.001 .366 -.004 .544',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M16 19h6', key: 'svg-1' }],
    ['path', { d: 'M19 16v6', key: 'svg-2' }],
  ],
);
