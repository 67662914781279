import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'shovel-pitchforks',
  'IconShovelPitchforks',
  [
    ['path', { d: 'M5 3h4', key: 'svg-0' }],
    ['path', { d: 'M7 3v12', key: 'svg-1' }],
    ['path', { d: 'M4 15h6v3a3 3 0 0 1 -6 0v-3z', key: 'svg-2' }],
    ['path', { d: 'M14 21v-3a3 3 0 0 1 6 0v3', key: 'svg-3' }],
    ['path', { d: 'M17 21v-18', key: 'svg-4' }],
  ],
);
