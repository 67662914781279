import createReactComponent from '../createReactComponent';
export default createReactComponent('coin-taka', 'IconCoinTaka', [
  [
    'path',
    {
      d: 'M8 8l.553 -.276a1 1 0 0 1 1.447 .894v6.382a2 2 0 0 0 2 2h.5a2.5 2.5 0 0 0 2.5 -2.5v-.5h-1',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M8 11h7', key: 'svg-1' }],
  ['path', { d: 'M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0', key: 'svg-2' }],
]);
