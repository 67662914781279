import createReactComponent from '../createReactComponent';
export default createReactComponent('brand-apple', 'IconBrandApple', [
  [
    'path',
    {
      d: 'M8.286 7.008c-3.216 0 -4.286 3.23 -4.286 5.92c0 3.229 2.143 8.072 4.286 8.072c1.165 -.05 1.799 -.538 3.214 -.538c1.406 0 1.607 .538 3.214 .538s4.286 -3.229 4.286 -5.381c-.03 -.011 -2.649 -.434 -2.679 -3.23c-.02 -2.335 2.589 -3.179 2.679 -3.228c-1.096 -1.606 -3.162 -2.113 -3.75 -2.153c-1.535 -.12 -3.032 1.077 -3.75 1.077c-.729 0 -2.036 -1.077 -3.214 -1.077z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M12 4a2 2 0 0 0 2 -2a2 2 0 0 0 -2 2', key: 'svg-1' }],
]);
