import createReactComponent from '../createReactComponent';
export default createReactComponent('flask-filled', 'IconFlaskFilled', [
  [
    'path',
    {
      d: 'M15 2a1 1 0 0 1 0 2v4.826l3.932 10.814l.034 .077a1.7 1.7 0 0 1 -.002 1.193l-.07 .162a1.7 1.7 0 0 1 -1.213 .911l-.181 .017h-11l-.181 -.017a1.7 1.7 0 0 1 -1.285 -2.266l.039 -.09l3.927 -10.804v-4.823a1 1 0 1 1 0 -2h6zm-2 2h-2v4h2v-4z',
      fill: 'currentColor',
      key: 'svg-0',
      strokeWidth: '0',
    },
  ],
]);
