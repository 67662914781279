import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'square-rounded-minus-2',
  'IconSquareRoundedMinus2',
  [
    [
      'path',
      {
        d: 'M12.5 21c-.18 .002 -.314 0 -.5 0c-7.2 0 -9 -1.8 -9 -9s1.8 -9 9 -9s9 1.8 9 9c0 1.136 -.046 2.138 -.152 3.02',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M16 19h6', key: 'svg-1' }],
  ],
);
