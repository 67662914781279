import createReactComponent from '../createReactComponent';
export default createReactComponent('square-plus-2', 'IconSquarePlus2', [
  [
    'path',
    {
      d: 'M12.5 21h-7.5a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v7.5',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M16 19h6', key: 'svg-1' }],
  ['path', { d: 'M19 16v6', key: 'svg-2' }],
]);
