import createReactComponent from '../createReactComponent';
export default createReactComponent('fingerprint-scan', 'IconFingerprintScan', [
  ['path', { d: 'M9 11a3 3 0 0 1 6 0c0 1.657 .612 3.082 1 4', key: 'svg-0' }],
  ['path', { d: 'M12 11v1.75c-.001 1.11 .661 2.206 1 3.25', key: 'svg-1' }],
  ['path', { d: 'M9 14.25c.068 .58 .358 1.186 .5 1.75', key: 'svg-2' }],
  ['path', { d: 'M4 8v-2a2 2 0 0 1 2 -2h2', key: 'svg-3' }],
  ['path', { d: 'M4 16v2a2 2 0 0 0 2 2h2', key: 'svg-4' }],
  ['path', { d: 'M16 4h2a2 2 0 0 1 2 2v2', key: 'svg-5' }],
  ['path', { d: 'M16 20h2a2 2 0 0 0 2 -2v-2', key: 'svg-6' }],
]);
