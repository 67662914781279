import createReactComponent from '../createReactComponent';
export default createReactComponent('number-5-small', 'IconNumber5Small', [
  [
    'path',
    {
      d: 'M10 15a1 1 0 0 0 1 1h2a1 1 0 0 0 1 -1v-2a1 1 0 0 0 -1 -1h-3v-4h4',
      key: 'svg-0',
    },
  ],
]);
