import createReactComponent from '../createReactComponent';
export default createReactComponent('sun-electricity', 'IconSunElectricity', [
  ['path', { d: 'M8 12a4 4 0 0 0 4 4m0 -8a4 4 0 0 0 -4 4', key: 'svg-0' }],
  ['path', { d: 'M3 12h1', key: 'svg-1' }],
  ['path', { d: 'M12 3v1', key: 'svg-2' }],
  ['path', { d: 'M12 20v1', key: 'svg-3' }],
  ['path', { d: 'M5.6 5.6l.7 .7', key: 'svg-4' }],
  ['path', { d: 'M6.3 17.7l-.7 .7', key: 'svg-5' }],
  ['path', { d: 'M20 7l-3 5h4l-3 5', key: 'svg-6' }],
]);
