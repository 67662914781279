import createReactComponent from '../createReactComponent';
export default createReactComponent('device-gamepad-3', 'IconDeviceGamepad3', [
  [
    'path',
    {
      d: 'M10 12l-3 -3h-2a1 1 0 0 0 -1 1v4a1 1 0 0 0 1 1h2l3 -3z',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M14 12l3 -3h2a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-2l-3 -3z',
      key: 'svg-1',
    },
  ],
  [
    'path',
    {
      d: 'M12 14l-3 3v2a1 1 0 0 0 1 1h4a1 1 0 0 0 1 -1v-2l-3 -3z',
      key: 'svg-2',
    },
  ],
  [
    'path',
    {
      d: 'M12 10l-3 -3v-2a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v2l-3 3z',
      key: 'svg-3',
    },
  ],
]);
