import createReactComponent from '../createReactComponent';
export default createReactComponent('photo-circle', 'IconPhotoCircle', [
  ['path', { d: 'M15 8h.01', key: 'svg-0' }],
  ['path', { d: 'M4 15l4 -4c.928 -.893 2.072 -.893 3 0l5 5', key: 'svg-1' }],
  ['path', { d: 'M14 14l1 -1c.928 -.893 2.072 -.893 3 0l2 2', key: 'svg-2' }],
  [
    'path',
    {
      d: 'M3 12a9 9 0 0 0 9 9a9 9 0 0 0 9 -9a9 9 0 0 0 -9 -9a9 9 0 0 0 -9 9',
      key: 'svg-3',
    },
  ],
]);
