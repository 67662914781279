import createReactComponent from '../createReactComponent';
export default createReactComponent('link-plus', 'IconLinkPlus', [
  ['path', { d: 'M9 15l6 -6', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M11 6l.463 -.536a5 5 0 0 1 7.072 0a4.993 4.993 0 0 1 -.001 7.072',
      key: 'svg-1',
    },
  ],
  [
    'path',
    {
      d: 'M12.603 18.534a5.07 5.07 0 0 1 -7.127 0a4.972 4.972 0 0 1 0 -7.071l.524 -.463',
      key: 'svg-2',
    },
  ],
  ['path', { d: 'M16 19h6', key: 'svg-3' }],
  ['path', { d: 'M19 16v6', key: 'svg-4' }],
]);
