import createReactComponent from '../createReactComponent';
export default createReactComponent('receipt-euro', 'IconReceiptEuro', [
  [
    'path',
    {
      d: 'M5 21v-16a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v16l-3 -2l-2 2l-2 -2l-2 2l-2 -2l-3 2',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M15 7.8c-.523 -.502 -1.172 -.8 -1.875 -.8c-1.727 0 -3.125 1.791 -3.125 4s1.398 4 3.125 4c.703 0 1.352 -.298 1.874 -.8',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M9 11h4', key: 'svg-2' }],
]);
