import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'circle-percentage',
  'IconCirclePercentage',
  [
    ['path', { d: 'M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0', key: 'svg-0' }],
    ['path', { d: 'M9 15.075l6 -6', key: 'svg-1' }],
    ['path', { d: 'M9 9.105v.015', key: 'svg-2' }],
    ['path', { d: 'M15 15.12v.015', key: 'svg-3' }],
  ],
);
