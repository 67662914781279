import createReactComponent from '../createReactComponent';
export default createReactComponent('12-hours', 'Icon12Hours', [
  ['path', { d: 'M20 11a8.1 8.1 0 0 0 -15.5 -2m-.5 -4v4h4', key: 'svg-0' }],
  ['path', { d: 'M4 13c.468 3.6 3.384 6.546 7 7', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M18 15h2a1 1 0 0 1 1 1v1a1 1 0 0 1 -1 1h-1a1 1 0 0 0 -1 1v1a1 1 0 0 0 1 1h2',
      key: 'svg-2',
    },
  ],
  ['path', { d: 'M15 21v-6', key: 'svg-3' }],
]);
