import createReactComponent from '../createReactComponent';
export default createReactComponent('number-29-small', 'IconNumber29Small', [
  [
    'path',
    {
      d: 'M14 15a1 1 0 0 0 1 1h2a1 1 0 0 0 1 -1v-6a1 1 0 0 0 -1 -1h-2a1 1 0 0 0 -1 1v2a1 1 0 0 0 1 1h3',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M6 8h3a1 1 0 0 1 1 1v2a1 1 0 0 1 -1 1h-2a1 1 0 0 0 -1 1v2a1 1 0 0 0 1 1h3',
      key: 'svg-1',
    },
  ],
]);
