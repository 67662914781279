import createReactComponent from '../createReactComponent';
export default createReactComponent('brand-auth0', 'IconBrandAuth0', [
  [
    'path',
    {
      d: 'M12 14.5l-5.5 3.5l2 -6l-4.5 -4h6l2 -5l2 5h6l-4.5 4l2 6z',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M20.507 8.872l-2.01 -5.872h-12.994l-2.009 5.872c-1.242 3.593 -.135 7.094 3.249 9.407l5.257 3.721l5.257 -3.721c3.385 -2.313 4.49 -5.814 3.25 -9.407z',
      key: 'svg-1',
    },
  ],
]);
