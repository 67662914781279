import createReactComponent from '../createReactComponent';
export default createReactComponent('bowl-spoon', 'IconBowlSpoon', [
  [
    'path',
    {
      d: 'M4 11h16a1 1 0 0 1 1 1v.5c0 1.5 -2.517 5.573 -4 6.5v1a1 1 0 0 1 -1 1h-8a1 1 0 0 1 -1 -1v-1c-1.687 -1.054 -4 -5 -4 -6.5v-.5a1 1 0 0 1 1 -1z',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M8 7c1.657 0 3 -.895 3 -2s-1.343 -2 -3 -2s-3 .895 -3 2s1.343 2 3 2',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M11 5h9', key: 'svg-2' }],
]);
