import createReactComponent from '../createReactComponent';
export default createReactComponent('list-letters', 'IconListLetters', [
  ['path', { d: 'M11 6h9', key: 'svg-0' }],
  ['path', { d: 'M11 12h9', key: 'svg-1' }],
  ['path', { d: 'M11 18h9', key: 'svg-2' }],
  ['path', { d: 'M4 10v-4.5a1.5 1.5 0 0 1 3 0v4.5', key: 'svg-3' }],
  ['path', { d: 'M4 8h3', key: 'svg-4' }],
  [
    'path',
    {
      d: 'M4 20h1.5a1.5 1.5 0 0 0 0 -3h-1.5h1.5a1.5 1.5 0 0 0 0 -3h-1.5v6z',
      key: 'svg-5',
    },
  ],
]);
