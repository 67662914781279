import createReactComponent from '../createReactComponent';
export default createReactComponent('currency-xrp', 'IconCurrencyXrp', [
  [
    'path',
    { d: 'M5 5l3.585 3.585a4.83 4.83 0 0 0 6.83 0l3.585 -3.585', key: 'svg-0' },
  ],
  [
    'path',
    {
      d: 'M5 19l3.585 -3.585a4.83 4.83 0 0 1 6.83 0l3.585 3.584',
      key: 'svg-1',
    },
  ],
]);
