import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'brackets-angle-off',
  'IconBracketsAngleOff',
  [
    ['path', { d: 'M8 4h.01', key: 'svg-0' }],
    ['path', { d: 'M6.453 6.474l-3.453 5.526l5 8', key: 'svg-1' }],
    ['path', { d: 'M16 4l5 8l-1.917 3.067', key: 'svg-2' }],
    ['path', { d: 'M17.535 17.544l-1.535 2.456', key: 'svg-3' }],
    ['path', { d: 'M3 3l18 18', key: 'svg-4' }],
  ],
);
