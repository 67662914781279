import createReactComponent from '../createReactComponent';
export default createReactComponent('vs', 'IconVs', [
  [
    'path',
    {
      d: 'M2 12c0 5.523 4.477 10 10 10s10 -4.477 10 -10s-4.477 -10 -10 -10s-10 4.477 -10 10',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M14 14.25c0 .414 .336 .75 .75 .75h1.25a1 1 0 0 0 1 -1v-1a1 1 0 0 0 -1 -1h-1a1 1 0 0 1 -1 -1v-1a1 1 0 0 1 1 -1h1.25a.75 .75 0 0 1 .75 .75',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M7 9l2 6l2 -6', key: 'svg-2' }],
]);
