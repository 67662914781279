import createReactComponent from '../createReactComponent';
export default createReactComponent('input-search', 'IconInputSearch', [
  [
    'path',
    {
      d: 'M20 11v-2a2 2 0 0 0 -2 -2h-12a2 2 0 0 0 -2 2v5a2 2 0 0 0 2 2h5',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M18 18m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0', key: 'svg-1' }],
  ['path', { d: 'M20.2 20.2l1.8 1.8', key: 'svg-2' }],
]);
