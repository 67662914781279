import createReactComponent from '../createReactComponent';
export default createReactComponent('receipt-bitcoin', 'IconReceiptBitcoin', [
  [
    'path',
    {
      d: 'M5 21v-16a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v16l-3 -2l-2 2l-2 -2l-2 2l-2 -2l-3 2',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M9 7h4.09c1.055 0 1.91 .895 1.91 2s-.855 2 -1.91 2c1.055 0 1.91 .895 1.91 2s-.855 2 -1.91 2h-4.09',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M10 11h4', key: 'svg-2' }],
  ['path', { d: 'M10 6v10v-9', key: 'svg-3' }],
  ['path', { d: 'M13 6v1', key: 'svg-4' }],
  ['path', { d: 'M13 15v1', key: 'svg-5' }],
]);
