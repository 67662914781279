import createReactComponent from '../createReactComponent';
export default createReactComponent('number-13-small', 'IconNumber13Small', [
  ['path', { d: 'M7 10l2 -2v8', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M13 8h2.5a1.5 1.5 0 0 1 1.5 1.5v1a1.5 1.5 0 0 1 -1.5 1.5h-1.5h1.5a1.5 1.5 0 0 1 1.5 1.5v1a1.5 1.5 0 0 1 -1.5 1.5h-2.5',
      key: 'svg-1',
    },
  ],
]);
