import createReactComponent from '../createReactComponent';
export default createReactComponent('triangle-plus-2', 'IconTrianglePlus2', [
  [
    'path',
    {
      d: 'M18.69 12.027l-5.054 -8.437a1.914 1.914 0 0 0 -3.274 0l-8.105 13.535a1.914 1.914 0 0 0 1.636 2.871h8.107',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M16 19h6', key: 'svg-1' }],
  ['path', { d: 'M19 16v6', key: 'svg-2' }],
]);
