import createReactComponent from '../createReactComponent';
export default createReactComponent('number-15-small', 'IconNumber15Small', [
  ['path', { d: 'M7 10l2 -2v8', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M13 15a1 1 0 0 0 1 1h2a1 1 0 0 0 1 -1v-2a1 1 0 0 0 -1 -1h-3v-4h4',
      key: 'svg-1',
    },
  ],
]);
