import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'transaction-pound',
  'IconTransactionPound',
  [
    ['path', { d: 'M21 14a2 2 0 1 0 -4 0v4a2 2 0 0 1 -2 2h6', key: 'svg-0' }],
    ['path', { d: 'M15 17h4', key: 'svg-1' }],
    ['path', { d: 'M5 5m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-2' }],
    ['path', { d: 'M17 5m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-3' }],
    ['path', { d: 'M7 5h8', key: 'svg-4' }],
    ['path', { d: 'M7 5v8a3 3 0 0 0 3 3h1', key: 'svg-5' }],
  ],
);
