import createReactComponent from '../createReactComponent';
export default createReactComponent('details', 'IconDetails', [
  ['path', { d: 'M11.999 3l.001 17', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M10.363 3.591l-8.106 13.534a1.914 1.914 0 0 0 1.636 2.871h16.214a1.914 1.914 0 0 0 1.636 -2.87l-8.106 -13.536a1.914 1.914 0 0 0 -3.274 0z',
      key: 'svg-1',
    },
  ],
]);
