import createReactComponent from '../createReactComponent';
export default createReactComponent('coin-yuan-filled', 'IconCoinYuanFilled', [
  [
    'path',
    {
      d: 'M17 3.34a10 10 0 1 1 -15 8.66l.005 -.324a10 10 0 0 1 14.995 -8.336zm-1.445 3.828a1 1 0 0 0 -1.387 .277l-2.168 3.251l-2.168 -3.25a1 1 0 0 0 -1.286 -.337l-.1 .059a1 1 0 0 0 -.278 1.387l2.296 3.445h-1.464a1 1 0 0 0 -.993 .883l-.007 .117a1 1 0 0 0 1 1h2v3a1 1 0 0 0 .883 .993l.117 .007l.117 -.007a1 1 0 0 0 .883 -.993v-3h2a1 1 0 0 0 .993 -.883l.007 -.117a1 1 0 0 0 -1 -1h-1.465l2.297 -3.445a1 1 0 0 0 -.184 -1.317l-.093 -.07z',
      fill: 'currentColor',
      key: 'svg-0',
      strokeWidth: '0',
    },
  ],
]);
