import createReactComponent from '../createReactComponent';
export default createReactComponent('favicon', 'IconFavicon', [
  [
    'path',
    {
      d: 'M2 5m0 3a3 3 0 0 1 3 -3h14a3 3 0 0 1 3 3v8a3 3 0 0 1 -3 3h-14a3 3 0 0 1 -3 -3z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M6 10v4', key: 'svg-1' }],
  ['path', { d: 'M11 10a2 2 0 1 0 0 4', key: 'svg-2' }],
  ['path', { d: 'M16 12m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-3' }],
]);
