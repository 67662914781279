import createReactComponent from '../createReactComponent';
export default createReactComponent('skew-x', 'IconSkewX', [
  [
    'path',
    {
      d: 'M4 5.205v13.59a1 1 0 0 0 1.184 .983l14 -2.625a1 1 0 0 0 .816 -.983v-8.34a1 1 0 0 0 -.816 -.983l-14 -2.625a1 1 0 0 0 -1.184 .983z',
      key: 'svg-0',
    },
  ],
]);
