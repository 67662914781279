import createReactComponent from '../createReactComponent';
export default createReactComponent('magnetic', 'IconMagnetic', [
  ['path', { d: 'M12 3v18', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M18 7c-.633 -1.255 -1.538 -2 -2.5 -2c-1.933 0 -3.5 3.134 -3.5 7s1.567 7 3.5 7s3.5 -3.134 3.5 -7v-1',
      key: 'svg-1',
    },
  ],
  [
    'path',
    {
      d: 'M6 7c.633 -1.255 1.538 -2 2.5 -2c1.933 0 3.5 3.134 3.5 7s-1.567 7 -3.5 7s-3.5 -3.134 -3.5 -7v-1',
      key: 'svg-2',
    },
  ],
  ['path', { d: 'M3 13l2 -2l2 2', key: 'svg-3' }],
  ['path', { d: 'M17 13l2 -2l2 2', key: 'svg-4' }],
]);
