import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'arrow-down-from-arc',
  'IconArrowDownFromArc',
  [
    ['path', { d: 'M12 15v-12', key: 'svg-0' }],
    ['path', { d: 'M16 7l-4 -4l-4 4', key: 'svg-1' }],
    ['path', { d: 'M3 12a9 9 0 0 0 18 0', key: 'svg-2' }],
  ],
);
