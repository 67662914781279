import createReactComponent from '../createReactComponent';
export default createReactComponent('letter-s-small', 'IconLetterSSmall', [
  [
    'path',
    {
      d: 'M10 15a1 1 0 0 0 1 1h2a1 1 0 0 0 1 -1v-2a1 1 0 0 0 -1 -1h-2a1 1 0 0 1 -1 -1v-2a1 1 0 0 1 1 -1h2a1 1 0 0 1 1 1',
      key: 'svg-0',
    },
  ],
]);
