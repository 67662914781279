import createReactComponent from '../createReactComponent';
export default createReactComponent('chart-scatter-3d', 'IconChartScatter3d', [
  ['path', { d: 'M3 20l9 -7', key: 'svg-0' }],
  ['path', { d: 'M12 3v10l9 7', key: 'svg-1' }],
  ['path', { d: 'M17 12v.015', key: 'svg-2' }],
  ['path', { d: 'M17 4.015v.015', key: 'svg-3' }],
  ['path', { d: 'M21 8.015v.015', key: 'svg-4' }],
  ['path', { d: 'M12 19.015v.015', key: 'svg-5' }],
  ['path', { d: 'M3 12.015v.015', key: 'svg-6' }],
  ['path', { d: 'M7 8.015v.015', key: 'svg-7' }],
  ['path', { d: 'M3 4.015v.015', key: 'svg-8' }],
]);
