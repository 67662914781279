import createReactComponent from '../createReactComponent';
export default createReactComponent('pin-invoke', 'IconPinInvoke', [
  [
    'path',
    {
      d: 'M21 13v5a1 1 0 0 1 -1 1h-16a1 1 0 0 1 -1 -1v-12a1 1 0 0 1 1 -1h9',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M19 7m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-1' }],
  ['path', { d: 'M10 11h4v4', key: 'svg-2' }],
  ['path', { d: 'M10 15l4 -4', key: 'svg-3' }],
]);
