import createReactComponent from '../createReactComponent';
export default createReactComponent('ear-scan', 'IconEarScan', [
  [
    'path',
    {
      d: 'M15 15a2 2 0 0 1 -2 2c-.732 0 -1.555 -.247 -1.72 -.98c-.634 -2.8 -3.17 -2.628 -3.28 -5.02v-.5a3.5 3.5 0 0 1 6.671 -1.483',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M4 8v-2a2 2 0 0 1 2 -2h2', key: 'svg-1' }],
  ['path', { d: 'M4 16v2a2 2 0 0 0 2 2h2', key: 'svg-2' }],
  ['path', { d: 'M16 4h2a2 2 0 0 1 2 2v2', key: 'svg-3' }],
  ['path', { d: 'M16 20h2a2 2 0 0 0 2 -2v-2', key: 'svg-4' }],
  ['path', { d: 'M13 12v.01', key: 'svg-5' }],
]);
