import createReactComponent from '../createReactComponent';
export default createReactComponent('crop-5-4', 'IconCrop54', [
  [
    'path',
    {
      d: 'M4 5m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v10a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z',
      key: 'svg-0',
    },
  ],
]);
