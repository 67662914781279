import createReactComponent from '../createReactComponent';
export default createReactComponent('brand-databricks', 'IconBrandDatabricks', [
  [
    'path',
    {
      d: 'M3 17l9 5l9 -5v-3l-9 5l-9 -5v-3l9 5l9 -5v-3l-9 5l-9 -5l9 -5l5.418 3.01',
      key: 'svg-0',
    },
  ],
]);
