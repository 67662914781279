import createReactComponent from '../createReactComponent';
export default createReactComponent('triangle-minus-2', 'IconTriangleMinus2', [
  [
    'path',
    {
      d: 'M20.48 15.016l-6.843 -11.426a1.914 1.914 0 0 0 -3.274 0l-8.106 13.535a1.914 1.914 0 0 0 1.636 2.871h8.107',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M16 19h6', key: 'svg-1' }],
]);
