import createReactComponent from '../createReactComponent';
export default createReactComponent('bookmark-edit', 'IconBookmarkEdit', [
  [
    'path',
    { d: 'M12 17l-6 4v-14a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v4', key: 'svg-0' },
  ],
  [
    'path',
    {
      d: 'M18.42 15.61a2.1 2.1 0 1 1 2.97 2.97l-3.39 3.42h-3v-3l3.42 -3.39z',
      key: 'svg-1',
    },
  ],
]);
