import createReactComponent from '../createReactComponent';
export default createReactComponent('route-scan', 'IconRouteScan', [
  ['path', { d: 'M4 8v-2a2 2 0 0 1 2 -2h2', key: 'svg-0' }],
  ['path', { d: 'M4 16v2a2 2 0 0 0 2 2h2', key: 'svg-1' }],
  ['path', { d: 'M16 4h2a2 2 0 0 1 2 2v2', key: 'svg-2' }],
  ['path', { d: 'M16 20h2a2 2 0 0 0 2 -2v-2', key: 'svg-3' }],
  ['path', { d: 'M7 12v-3h3', key: 'svg-4' }],
  ['path', { d: 'M14 9h3v3', key: 'svg-5' }],
  ['path', { d: 'M7 9l4.414 4.414a2 2 0 0 1 .586 1.414v2.172', key: 'svg-6' }],
  [
    'path',
    { d: 'M17 9l-4.414 4.414a2 2 0 0 0 -.586 1.414v2.172', key: 'svg-7' },
  ],
]);
