import createReactComponent from '../createReactComponent';
export default createReactComponent('universe', 'IconUniverse', [
  [
    'path',
    {
      d: 'M7.027 11.477a5 5 0 1 0 5.496 -4.45a4.951 4.951 0 0 0 -3.088 .681',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M5.636 5.636a9 9 0 1 0 3.555 -2.188', key: 'svg-1' }],
  ['path', { d: 'M18 5m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0', key: 'svg-2' }],
  ['path', { d: 'M12 12m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0', key: 'svg-3' }],
  ['path', { d: 'M9 16m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0', key: 'svg-4' }],
]);
