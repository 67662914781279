import createReactComponent from '../createReactComponent';
export default createReactComponent('crop-3-2', 'IconCrop32', [
  [
    'path',
    {
      d: 'M4 7m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v6a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z',
      key: 'svg-0',
    },
  ],
]);
