import createReactComponent from '../createReactComponent';
export default createReactComponent('melon', 'IconMelon', [
  [
    'path',
    {
      d: 'M20 10c0 5.523 -4.477 10 -10 10a9.967 9.967 0 0 1 -6.984 -2.842l4.343 -4.153a4 4 0 0 0 5.76 -5.51l4.342 -4.153a9.963 9.963 0 0 1 2.539 6.658z',
      key: 'svg-0',
    },
  ],
]);
