import createReactComponent from '../createReactComponent';
export default createReactComponent('rv-truck', 'IconRvTruck', [
  ['path', { d: 'M7 17m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-0' }],
  ['path', { d: 'M17 17m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-1' }],
  ['path', { d: 'M9 17h6', key: 'svg-2' }],
  [
    'path',
    {
      d: 'M19 17h1a1 1 0 0 0 1 -1v-4.528a2 2 0 0 0 -.211 -.894l-.96 -1.92a3 3 0 0 0 -2.683 -1.658h-11.146a3 3 0 0 0 -3 3v6a1 1 0 0 0 1 1h1',
      key: 'svg-3',
    },
  ],
  ['path', { d: 'M3 12h18', key: 'svg-4' }],
  ['path', { d: 'M15 12v-5', key: 'svg-5' }],
  [
    'path',
    {
      d: 'M6 4m0 1.5a1.5 1.5 0 0 1 1.5 -1.5h7a1.5 1.5 0 0 1 1.5 1.5v0a1.5 1.5 0 0 1 -1.5 1.5h-7a1.5 1.5 0 0 1 -1.5 -1.5z',
      key: 'svg-6',
    },
  ],
]);
