import createReactComponent from '../createReactComponent';
export default createReactComponent('number-23-small', 'IconNumber23Small', [
  [
    'path',
    {
      d: 'M13 8h2.5a1.5 1.5 0 0 1 1.5 1.5v1a1.5 1.5 0 0 1 -1.5 1.5h-1.5h1.5a1.5 1.5 0 0 1 1.5 1.5v1a1.5 1.5 0 0 1 -1.5 1.5h-2.5',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M6 8h3a1 1 0 0 1 1 1v2a1 1 0 0 1 -1 1h-2a1 1 0 0 0 -1 1v2a1 1 0 0 0 1 1h3',
      key: 'svg-1',
    },
  ],
]);
