import createReactComponent from '../createReactComponent';
export default createReactComponent('letter-o-small', 'IconLetterOSmall', [
  [
    'path',
    {
      d: 'M12 8a2 2 0 0 1 2 2v4a2 2 0 1 1 -4 0v-4a2 2 0 0 1 2 -2z',
      key: 'svg-0',
    },
  ],
]);
