import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'automatic-gearbox',
  'IconAutomaticGearbox',
  [
    ['path', { d: 'M17 17v4h1a2 2 0 1 0 0 -4h-1z', key: 'svg-0' }],
    ['path', { d: 'M17 11h1.5a1.5 1.5 0 0 0 0 -3h-1.5v5', key: 'svg-1' }],
    ['path', { d: 'M5 5m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-2' }],
    ['path', { d: 'M5 7v3a1 1 0 0 0 1 1h3v7a1 1 0 0 0 1 1h3', key: 'svg-3' }],
    ['path', { d: 'M9 11h4', key: 'svg-4' }],
  ],
);
