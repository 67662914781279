import createReactComponent from '../createReactComponent';
export default createReactComponent('robot-off', 'IconRobotOff', [
  [
    'path',
    {
      d: 'M8 4h8a2 2 0 0 1 2 2v4a2 2 0 0 1 -2 2m-4 0h-4a2 2 0 0 1 -2 -2v-4',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M12 2v2', key: 'svg-1' }],
  ['path', { d: 'M9 12v9', key: 'svg-2' }],
  ['path', { d: 'M15 15v6', key: 'svg-3' }],
  ['path', { d: 'M5 16l4 -2', key: 'svg-4' }],
  ['path', { d: 'M9 18h6', key: 'svg-5' }],
  ['path', { d: 'M14 8v.01', key: 'svg-6' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-7' }],
]);
