import createReactComponent from '../createReactComponent';
export default createReactComponent('pin-end', 'IconPinEnd', [
  [
    'path',
    {
      d: 'M21 11v-5a1 1 0 0 0 -1 -1h-16a1 1 0 0 0 -1 1v12a1 1 0 0 0 1 1h9',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M19 17m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-1' }],
  ['path', { d: 'M10 13v-4h4', key: 'svg-2' }],
  ['path', { d: 'M14 13l-4 -4', key: 'svg-3' }],
]);
