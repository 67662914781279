import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'sort-deacending-small-big',
  'IconSortDeacendingSmallBig',
  [
    ['path', { d: 'M10 15l-3 3l-3 -3', key: 'svg-0' }],
    ['path', { d: 'M7 6v12', key: 'svg-1' }],
    [
      'path',
      {
        d: 'M14 18.333c0 .369 .298 .667 .667 .667h2.666a.667 .667 0 0 0 .667 -.667v-2.666a.667 .667 0 0 0 -.667 -.667h-2.666a.667 .667 0 0 0 -.667 .667v2.666z',
        key: 'svg-2',
      },
    ],
    [
      'path',
      {
        d: 'M14 10.833c0 .645 .522 1.167 1.167 1.167h4.666c.645 0 1.167 -.522 1.167 -1.167v-4.666c0 -.645 -.522 -1.167 -1.167 -1.167h-4.666c-.645 0 -1.167 .522 -1.167 1.167v4.666z',
        key: 'svg-3',
      },
    ],
  ],
);
