import createReactComponent from '../createReactComponent';
export default createReactComponent('number-6-small', 'IconNumber6Small', [
  [
    'path',
    {
      d: 'M14 9a1 1 0 0 0 -1 -1h-2a1 1 0 0 0 -1 1v6a1 1 0 0 0 1 1h2a1 1 0 0 0 1 -1v-2a1 1 0 0 0 -1 -1h-3',
      key: 'svg-0',
    },
  ],
]);
