import createReactComponent from '../createReactComponent';
export default createReactComponent('sticker-2', 'IconSticker2', [
  [
    'path',
    {
      d: 'M6 4h12a2 2 0 0 1 2 2v7h-5a2 2 0 0 0 -2 2v5h-7a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2z',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M20 13v.172a2 2 0 0 1 -.586 1.414l-4.828 4.828a2 2 0 0 1 -1.414 .586h-.172',
      key: 'svg-1',
    },
  ],
]);
