import createReactComponent from '../createReactComponent';
export default createReactComponent('folder-root', 'IconFolderRoot', [
  ['path', { d: 'M12 13m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-0' }],
  ['path', { d: 'M12 15v4', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M5 4h4l3 3h7a2 2 0 0 1 2 2v8a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-11a2 2 0 0 1 2 -2',
      key: 'svg-2',
    },
  ],
]);
