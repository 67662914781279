import createReactComponent from '../createReactComponent';
export default createReactComponent('number-22-small', 'IconNumber22Small', [
  [
    'path',
    {
      d: 'M14 8h3a1 1 0 0 1 1 1v2a1 1 0 0 1 -1 1h-2a1 1 0 0 0 -1 1v2a1 1 0 0 0 1 1h3',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M6 8h3a1 1 0 0 1 1 1v2a1 1 0 0 1 -1 1h-2a1 1 0 0 0 -1 1v2a1 1 0 0 0 1 1h3',
      key: 'svg-1',
    },
  ],
]);
