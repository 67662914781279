import createReactComponent from '../createReactComponent';
export default createReactComponent('brand-meetup', 'IconBrandMeetup', [
  [
    'path',
    {
      d: 'M5.455 10.82c.935 -2.163 3.045 -3.82 5.545 -3.82c2.104 0 2.844 1.915 2 4l-2 6',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M6.981 7l-3.981 9.914', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M13 11c.937 -2.16 3.071 -3.802 5.42 -3.972c2.104 0 3.128 1.706 2.284 3.792l-2.454 6.094c-.853 1.676 .75 2.586 2.75 2.086',
      key: 'svg-2',
    },
  ],
]);
