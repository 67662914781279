import createReactComponent from '../createReactComponent';
export default createReactComponent('photo-video', 'IconPhotoVideo', [
  [
    'path',
    {
      d: 'M9 15h-3a3 3 0 0 1 -3 -3v-6a3 3 0 0 1 3 -3h6a3 3 0 0 1 3 3v3',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M9 9m0 3a3 3 0 0 1 3 -3h6a3 3 0 0 1 3 3v6a3 3 0 0 1 -3 3h-6a3 3 0 0 1 -3 -3z',
      key: 'svg-1',
    },
  ],
  [
    'path',
    { d: 'M3 12l2.296 -2.296a2.41 2.41 0 0 1 3.408 0l.296 .296', key: 'svg-2' },
  ],
  ['path', { d: 'M14 13.5v3l2.5 -1.5z', key: 'svg-3' }],
  ['path', { d: 'M7 6v.01', key: 'svg-4' }],
]);
