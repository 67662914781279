import createReactComponent from '../createReactComponent';
export default createReactComponent('bookmark-ai', 'IconBookmarkAi', [
  [
    'path',
    {
      d: 'M10.02 18.32l-4.02 2.68v-14a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v4.5',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M14 21v-4a2 2 0 1 1 4 0v4', key: 'svg-1' }],
  ['path', { d: 'M14 19h4', key: 'svg-2' }],
  ['path', { d: 'M21 15v6', key: 'svg-3' }],
]);
