import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'square-rounded-percentage',
  'IconSquareRoundedPercentage',
  [
    [
      'path',
      {
        d: 'M12 3c7.2 0 9 1.8 9 9s-1.8 9 -9 9s-9 -1.8 -9 -9s1.8 -9 9 -9z',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M9 15.075l6 -6', key: 'svg-1' }],
    ['path', { d: 'M9 9.105v.015', key: 'svg-2' }],
    ['path', { d: 'M15 15.12v.015', key: 'svg-3' }],
  ],
);
