import createReactComponent from '../createReactComponent';
export default createReactComponent('stack-middle', 'IconStackMiddle', [
  ['path', { d: 'M16 10l4 -2l-8 -4l-8 4l4 2', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M12 12l-4 -2l-4 2l8 4l8 -4l-4 -2l-4 2z',
      fill: 'currentColor',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M8 14l-4 2l8 4l8 -4l-4 -2', key: 'svg-2' }],
]);
