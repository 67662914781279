import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'photo-circle-minus',
  'IconPhotoCircleMinus',
  [
    ['path', { d: 'M15 8h.01', key: 'svg-0' }],
    [
      'path',
      {
        d: 'M20.475 15.035a9 9 0 0 0 -8.475 -12.035a9 9 0 0 0 -9 9a9 9 0 0 0 9.525 8.985',
        key: 'svg-1',
      },
    ],
    ['path', { d: 'M4 15l4 -4c.928 -.893 2.072 -.893 3 0l4 4', key: 'svg-2' }],
    ['path', { d: 'M14 14l1 -1c.928 -.893 2.072 -.893 3 0l2 2', key: 'svg-3' }],
    ['path', { d: 'M16 19h6', key: 'svg-4' }],
  ],
);
