import createReactComponent from '../createReactComponent';
export default createReactComponent('triangle-plus', 'IconTrianglePlus', [
  [
    'path',
    {
      d: 'M10.363 3.591l-8.106 13.534a1.914 1.914 0 0 0 1.636 2.871h16.214a1.914 1.914 0 0 0 1.636 -2.87l-8.106 -13.536a1.914 1.914 0 0 0 -3.274 0v.001z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M9 13h6', key: 'svg-1' }],
  ['path', { d: 'M12 10v6', key: 'svg-2' }],
]);
