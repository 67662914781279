import createReactComponent from '../createReactComponent';
export default createReactComponent('galaxy', 'IconGalaxy', [
  [
    'path',
    {
      d: 'M12 3c-1.333 1 -2 2.5 -2 4.5c0 3 2 4.5 2 4.5s2 1.5 2 4.5c0 2 -.667 3.5 -2 4.5',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M19.794 16.5c-.2 -1.655 -1.165 -2.982 -2.897 -3.982c-2.597 -1.5 -4.897 -.518 -4.897 -.518s-2.299 .982 -4.897 -.518c-1.732 -1 -2.698 -2.327 -2.897 -3.982',
      key: 'svg-1',
    },
  ],
  [
    'path',
    {
      d: 'M19.794 7.5c-1.532 -.655 -3.165 -.482 -4.897 .518c-2.597 1.5 -2.897 3.982 -2.897 3.982s-.299 2.482 -2.897 3.982c-1.732 1 -3.365 1.173 -4.897 .518',
      key: 'svg-2',
    },
  ],
]);
