import createReactComponent from '../createReactComponent';
export default createReactComponent('transaction-yuan', 'IconTransactionYuan', [
  ['path', { d: 'M15 17h6', key: 'svg-0' }],
  ['path', { d: 'M15 12l3 4.5', key: 'svg-1' }],
  ['path', { d: 'M21 12l-3 4.5v4.5', key: 'svg-2' }],
  ['path', { d: 'M5 5m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-3' }],
  ['path', { d: 'M17 5m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-4' }],
  ['path', { d: 'M7 5h8', key: 'svg-5' }],
  ['path', { d: 'M7 5v8a3 3 0 0 0 3 3h1', key: 'svg-6' }],
]);
