import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'arrow-right-to-arc',
  'IconArrowRightToArc',
  [
    ['path', { d: 'M3 12h12', key: 'svg-0' }],
    ['path', { d: 'M11 8l4 4l-4 4', key: 'svg-1' }],
    ['path', { d: 'M12 21a9 9 0 0 0 0 -18', key: 'svg-2' }],
  ],
);
