import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'arrow-left-from-arc',
  'IconArrowLeftFromArc',
  [
    ['path', { d: 'M9 12h12', key: 'svg-0' }],
    ['path', { d: 'M17 16l4 -4l-4 -4', key: 'svg-1' }],
    ['path', { d: 'M12 3a9 9 0 1 0 0 18', key: 'svg-2' }],
  ],
);
