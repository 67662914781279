import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'waves-electricity',
  'IconWavesElectricity',
  [
    [
      'path',
      {
        d: 'M3 12c.576 -.643 1.512 -1.017 2.5 -1c.988 -.017 1.924 .357 2.5 1c.576 .643 1.512 1.017 2.5 1c.988 .017 1.924 -.357 2.5 -1',
        key: 'svg-0',
      },
    ],
    [
      'path',
      {
        d: 'M3 16c.576 -.643 1.512 -1.017 2.5 -1c.988 -.017 1.924 .357 2.5 1c.576 .643 1.512 1.017 2.5 1c.988 .017 1.924 -.357 2.5 -1',
        key: 'svg-1',
      },
    ],
    [
      'path',
      {
        d: 'M3 8c.576 -.643 1.512 -1.017 2.5 -1c.988 -.017 1.924 .357 2.5 1c.576 .643 1.512 1.017 2.5 1c.988 .017 1.924 -.357 2.5 -1',
        key: 'svg-2',
      },
    ],
    ['path', { d: 'M20 7l-3 5h4l-3 5', key: 'svg-3' }],
  ],
);
