import createReactComponent from '../createReactComponent';
export default createReactComponent('clock-12', 'IconClock12', [
  ['path', { d: 'M3 12a9 9 0 0 0 9 9m9 -9a9 9 0 1 0 -18 0', key: 'svg-0' }],
  ['path', { d: 'M12 7v5l.5 .5', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M18 15h2a1 1 0 0 1 1 1v1a1 1 0 0 1 -1 1h-1a1 1 0 0 0 -1 1v1a1 1 0 0 0 1 1h2',
      key: 'svg-2',
    },
  ],
  ['path', { d: 'M15 21v-6', key: 'svg-3' }],
]);
