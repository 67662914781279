import createReactComponent from '../createReactComponent';
export default createReactComponent('password-user', 'IconPasswordUser', [
  ['path', { d: 'M12 17v4', key: 'svg-0' }],
  ['path', { d: 'M10 20l4 -2', key: 'svg-1' }],
  ['path', { d: 'M10 18l4 2', key: 'svg-2' }],
  ['path', { d: 'M5 17v4', key: 'svg-3' }],
  ['path', { d: 'M3 20l4 -2', key: 'svg-4' }],
  ['path', { d: 'M3 18l4 2', key: 'svg-5' }],
  ['path', { d: 'M19 17v4', key: 'svg-6' }],
  ['path', { d: 'M17 20l4 -2', key: 'svg-7' }],
  ['path', { d: 'M17 18l4 2', key: 'svg-8' }],
  ['path', { d: 'M9 6a3 3 0 1 0 6 0a3 3 0 0 0 -6 0', key: 'svg-9' }],
  ['path', { d: 'M7 14a2 2 0 0 1 2 -2h6a2 2 0 0 1 2 2', key: 'svg-10' }],
]);
