import createReactComponent from '../createReactComponent';
export default createReactComponent('number-24-small', 'IconNumber24Small', [
  ['path', { d: 'M14 8v3a1 1 0 0 0 1 1h3', key: 'svg-0' }],
  ['path', { d: 'M18 8v8', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M6 8h3a1 1 0 0 1 1 1v2a1 1 0 0 1 -1 1h-2a1 1 0 0 0 -1 1v2a1 1 0 0 0 1 1h3',
      key: 'svg-2',
    },
  ],
]);
