import createReactComponent from '../createReactComponent';
export default createReactComponent('receipt-rupee', 'IconReceiptRupee', [
  [
    'path',
    {
      d: 'M5 21v-16a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v16l-3 -2l-2 2l-2 -2l-2 2l-2 -2l-3 2',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M15 7h-6h1a3 3 0 0 1 0 6h-1l3 3', key: 'svg-1' }],
  ['path', { d: 'M9 10h6', key: 'svg-2' }],
]);
