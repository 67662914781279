import createReactComponent from '../createReactComponent';
export default createReactComponent('bookmark-off', 'IconBookmarkOff', [
  [
    'path',
    {
      d: 'M7.708 3.721a3.982 3.982 0 0 1 2.292 -.721h4a4 4 0 0 1 4 4v7m0 4v3l-6 -4l-6 4v-14c0 -.308 .035 -.609 .1 -.897',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M3 3l18 18', key: 'svg-1' }],
]);
