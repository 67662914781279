import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'arrow-merge-alt-right',
  'IconArrowMergeAltRight',
  [
    ['path', { d: 'M16 7l-4 -4l-4 4', key: 'svg-0' }],
    ['path', { d: 'M6 21v.01', key: 'svg-1' }],
    ['path', { d: 'M6 18.01v.01', key: 'svg-2' }],
    ['path', { d: 'M7 15.02v.01', key: 'svg-3' }],
    ['path', { d: 'M10 13.03v.01', key: 'svg-4' }],
    [
      'path',
      {
        d: 'M12 3v5.394a6.737 6.737 0 0 0 3 5.606a6.737 6.737 0 0 1 3 5.606v1.394',
        key: 'svg-5',
      },
    ],
  ],
);
