import createReactComponent from '../createReactComponent';
export default createReactComponent('receipt-pound', 'IconReceiptPound', [
  [
    'path',
    {
      d: 'M5 21v-16a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v16l-3 -2l-2 2l-2 -2l-2 2l-2 -2l-3 2',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M15 9a2 2 0 1 0 -4 0v4a2 2 0 0 1 -2 2h6', key: 'svg-1' }],
  ['path', { d: 'M9 12h4', key: 'svg-2' }],
]);
