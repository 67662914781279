import createReactComponent from '../createReactComponent';
export default createReactComponent('number-8-small', 'IconNumber8Small', [
  [
    'path',
    {
      d: 'M12 12h-1a1 1 0 0 1 -1 -1v-2a1 1 0 0 1 1 -1h2a1 1 0 0 1 1 1v2a1 1 0 0 1 -1 1h-2a1 1 0 0 0 -1 1v2a1 1 0 0 0 1 1h2a1 1 0 0 0 1 -1v-2a1 1 0 0 0 -1 -1',
      key: 'svg-0',
    },
  ],
]);
