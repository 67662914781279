import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'arrow-right-from-arc',
  'IconArrowRightFromArc',
  [
    ['path', { d: 'M15 12h-12', key: 'svg-0' }],
    ['path', { d: 'M7 8l-4 4l4 4', key: 'svg-1' }],
    ['path', { d: 'M12 21a9 9 0 0 0 0 -18', key: 'svg-2' }],
  ],
);
