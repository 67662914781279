import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'square-percentage',
  'IconSquarePercentage',
  [
    [
      'path',
      {
        d: 'M3 5a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v14a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-14z',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M9 15.037l6 -6', key: 'svg-1' }],
    ['path', { d: 'M9 9.068v.014', key: 'svg-2' }],
    ['path', { d: 'M15 15.082v.016', key: 'svg-3' }],
  ],
);
