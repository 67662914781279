import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'pentagon-number-0',
  'IconPentagonNumber0',
  [
    [
      'path',
      {
        d: 'M13.163 2.168l8.021 5.828c.694 .504 .984 1.397 .719 2.212l-3.064 9.43a1.978 1.978 0 0 1 -1.881 1.367h-9.916a1.978 1.978 0 0 1 -1.881 -1.367l-3.064 -9.43a1.978 1.978 0 0 1 .719 -2.212l8.021 -5.828a1.978 1.978 0 0 1 2.326 0z',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M10 10v4a2 2 0 1 0 4 0v-4a2 2 0 1 0 -4 0z', key: 'svg-1' }],
  ],
);
