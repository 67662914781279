import createReactComponent from '../createReactComponent';
export default createReactComponent('receipt-yen', 'IconReceiptYen', [
  [
    'path',
    {
      d: 'M5 21v-16a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v16l-3 -2l-2 2l-2 -2l-2 2l-2 -2l-3 2',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M9 11h6', key: 'svg-1' }],
  ['path', { d: 'M9 14h6', key: 'svg-2' }],
  ['path', { d: 'M9 7l3 4.5', key: 'svg-3' }],
  ['path', { d: 'M15 7l-3 4.5v4.5', key: 'svg-4' }],
]);
