import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'solar-electricity',
  'IconSolarElectricity',
  [
    [
      'path',
      {
        d: 'M4 6.28v11.44a1 1 0 0 0 1.243 .97l6 -1.5a1 1 0 0 0 .757 -.97v-8.44a1 1 0 0 0 -.757 -.97l-6 -1.5a1 1 0 0 0 -1.243 .97z',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M8 6v12', key: 'svg-1' }],
    ['path', { d: 'M12 12h-8', key: 'svg-2' }],
    ['path', { d: 'M20 7l-3 5h4l-3 5', key: 'svg-3' }],
  ],
);
