import createReactComponent from '../createReactComponent';
export default createReactComponent('body-scan', 'IconBodyScan', [
  ['path', { d: 'M4 8v-2a2 2 0 0 1 2 -2h2', key: 'svg-0' }],
  ['path', { d: 'M4 16v2a2 2 0 0 0 2 2h2', key: 'svg-1' }],
  ['path', { d: 'M16 4h2a2 2 0 0 1 2 2v2', key: 'svg-2' }],
  ['path', { d: 'M16 20h2a2 2 0 0 0 2 -2v-2', key: 'svg-3' }],
  ['path', { d: 'M12 8m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0', key: 'svg-4' }],
  ['path', { d: 'M10 17v-1a2 2 0 1 1 4 0v1', key: 'svg-5' }],
  [
    'path',
    {
      d: 'M8 10c.666 .666 1.334 1 2 1h4c.666 0 1.334 -.334 2 -1',
      key: 'svg-6',
    },
  ],
  ['path', { d: 'M12 11v3', key: 'svg-7' }],
]);
