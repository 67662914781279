import createReactComponent from '../createReactComponent';
export default createReactComponent('transaction-euro', 'IconTransactionEuro', [
  [
    'path',
    {
      d: 'M21 12.8c-.523 -.502 -1.172 -.8 -1.875 -.8c-1.727 0 -3.125 1.791 -3.125 4s1.398 4 3.125 4c.703 0 1.352 -.298 1.874 -.8',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M15 16h4', key: 'svg-1' }],
  ['path', { d: 'M5 5m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-2' }],
  ['path', { d: 'M17 5m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-3' }],
  ['path', { d: 'M7 5h8', key: 'svg-4' }],
  ['path', { d: 'M7 5v8a3 3 0 0 0 3 3h1', key: 'svg-5' }],
]);
