import createReactComponent from '../createReactComponent';
export default createReactComponent('crop-16-9', 'IconCrop169', [
  [
    'path',
    {
      d: 'M4 8m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v4a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z',
      key: 'svg-0',
    },
  ],
]);
