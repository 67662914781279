import createReactComponent from '../createReactComponent';
export default createReactComponent('category-plus', 'IconCategoryPlus', [
  [
    'path',
    {
      d: 'M4 4h6v6h-6zm10 0h6v6h-6zm-10 10h6v6h-6zm10 3h6m-3 -3v6',
      key: 'svg-0',
    },
  ],
]);
