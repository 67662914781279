import createReactComponent from '../createReactComponent';
export default createReactComponent('bowl-chopsticks', 'IconBowlChopsticks', [
  [
    'path',
    {
      d: 'M4 11h16a1 1 0 0 1 1 1v.5c0 1.5 -2.517 5.573 -4 6.5v1a1 1 0 0 1 -1 1h-8a1 1 0 0 1 -1 -1v-1c-1.687 -1.054 -4 -5 -4 -6.5v-.5a1 1 0 0 1 1 -1z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M19 7l-14 1', key: 'svg-1' }],
  ['path', { d: 'M19 2l-14 3', key: 'svg-2' }],
]);
