import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'photo-square-rounded',
  'IconPhotoSquareRounded',
  [
    ['path', { d: 'M15 8h.01', key: 'svg-0' }],
    [
      'path',
      {
        d: 'M12 3c7.2 0 9 1.8 9 9s-1.8 9 -9 9s-9 -1.8 -9 -9s1.8 -9 9 -9z',
        key: 'svg-1',
      },
    ],
    [
      'path',
      { d: 'M3.5 15.5l4.5 -4.5c.928 -.893 2.072 -.893 3 0l5 5', key: 'svg-2' },
    ],
    [
      'path',
      { d: 'M14 14l1 -1c.928 -.893 2.072 -.893 3 0l2.5 2.5', key: 'svg-3' },
    ],
  ],
);
