import createReactComponent from '../createReactComponent';
export default createReactComponent('stack-back', 'IconStackBack', [
  ['path', { d: 'M4 8l8 4l8 -4l-8 -4z', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M12 16l-4 -2l-4 2l8 4l8 -4l-4 -2l-4 2z',
      fill: 'currentColor',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M8 10l-4 2l4 2m8 0l4 -2l-4 -2', key: 'svg-2' }],
]);
