import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'password-fingerprint',
  'IconPasswordFingerprint',
  [
    ['path', { d: 'M17 8c.788 1 1 2 1 3v1', key: 'svg-0' }],
    ['path', { d: 'M9 11c0 -1.578 1.343 -3 3 -3s3 1.422 3 3v2', key: 'svg-1' }],
    ['path', { d: 'M12 11v2', key: 'svg-2' }],
    [
      'path',
      {
        d: 'M6 12v-1.397c-.006 -1.999 1.136 -3.849 2.993 -4.85a6.385 6.385 0 0 1 6.007 -.005',
        key: 'svg-3',
      },
    ],
    ['path', { d: 'M12 17v4', key: 'svg-4' }],
    ['path', { d: 'M10 20l4 -2', key: 'svg-5' }],
    ['path', { d: 'M10 18l4 2', key: 'svg-6' }],
    ['path', { d: 'M5 17v4', key: 'svg-7' }],
    ['path', { d: 'M3 20l4 -2', key: 'svg-8' }],
    ['path', { d: 'M3 18l4 2', key: 'svg-9' }],
    ['path', { d: 'M19 17v4', key: 'svg-10' }],
    ['path', { d: 'M17 20l4 -2', key: 'svg-11' }],
    ['path', { d: 'M17 18l4 2', key: 'svg-12' }],
  ],
);
