import createReactComponent from '../createReactComponent';
export default createReactComponent('car-garage', 'IconCarGarage', [
  ['path', { d: 'M5 20a2 2 0 1 0 4 0a2 2 0 0 0 -4 0', key: 'svg-0' }],
  ['path', { d: 'M15 20a2 2 0 1 0 4 0a2 2 0 0 0 -4 0', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M5 20h-2v-6l2 -5h9l4 5h1a2 2 0 0 1 2 2v4h-2m-4 0h-6m-6 -6h15m-6 0v-5',
      key: 'svg-2',
    },
  ],
  ['path', { d: 'M3 6l9 -4l9 4', key: 'svg-3' }],
]);
