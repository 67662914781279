import createReactComponent from '../createReactComponent';
export default createReactComponent('crop-portrait', 'IconCropPortrait', [
  [
    'path',
    {
      d: 'M6 4m0 2a2 2 0 0 1 2 -2h8a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-8a2 2 0 0 1 -2 -2z',
      key: 'svg-0',
    },
  ],
]);
