import createReactComponent from '../createReactComponent';
export default createReactComponent('number-12-small', 'IconNumber12Small', [
  ['path', { d: 'M7 10l2 -2v8', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M13 8h3a1 1 0 0 1 1 1v2a1 1 0 0 1 -1 1h-2a1 1 0 0 0 -1 1v2a1 1 0 0 0 1 1h3',
      key: 'svg-1',
    },
  ],
]);
