import createReactComponent from '../createReactComponent';
export default createReactComponent('alert-circle-off', 'IconAlertCircleOff', [
  [
    'path',
    {
      d: 'M5.644 5.629a9 9 0 1 0 12.715 12.741m1.693 -2.349a9 9 0 0 0 -12.087 -12.068',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M12 7v1', key: 'svg-1' }],
  ['path', { d: 'M12 16h.01', key: 'svg-2' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-3' }],
]);
