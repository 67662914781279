import createReactComponent from '../createReactComponent';
export default createReactComponent('calendar-clock', 'IconCalendarClock', [
  [
    'path',
    {
      d: 'M10.5 21h-4.5a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v3',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M16 3v4', key: 'svg-1' }],
  ['path', { d: 'M8 3v4', key: 'svg-2' }],
  ['path', { d: 'M4 11h10', key: 'svg-3' }],
  ['path', { d: 'M18 18m-4 0a4 4 0 1 0 8 0a4 4 0 1 0 -8 0', key: 'svg-4' }],
  ['path', { d: 'M18 16.5v1.5l.5 .5', key: 'svg-5' }],
]);
