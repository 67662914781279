import createReactComponent from '../createReactComponent';
export default createReactComponent('number-2-small', 'IconNumber2Small', [
  [
    'path',
    {
      d: 'M10 8h3a1 1 0 0 1 1 1v2a1 1 0 0 1 -1 1h-2a1 1 0 0 0 -1 1v2a1 1 0 0 0 1 1h3',
      key: 'svg-0',
    },
  ],
]);
