import createReactComponent from '../createReactComponent';
export default createReactComponent('meter-square', 'IconMeterSquare', [
  [
    'path',
    {
      d: 'M17 5h2a1 1 0 0 1 1 1v1a1 1 0 0 1 -1 1h-1a1 1 0 0 0 -1 1v1a1 1 0 0 0 1 1h2',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M4 12v6', key: 'svg-1' }],
  [
    'path',
    { d: 'M4 14a2 2 0 0 1 2 -2h.5a2.5 2.5 0 0 1 2.5 2.5v3.5', key: 'svg-2' },
  ],
  ['path', { d: 'M9 15.5v-1a2.5 2.5 0 1 1 5 0v3.5', key: 'svg-3' }],
]);
