import createReactComponent from '../createReactComponent';
export default createReactComponent('send-2', 'IconSend2', [
  [
    'path',
    {
      d: 'M4.698 4.034l16.302 7.966l-16.302 7.966a.503 .503 0 0 1 -.546 -.124a.555 .555 0 0 1 -.12 -.568l2.468 -7.274l-2.468 -7.274a.555 .555 0 0 1 .12 -.568a.503 .503 0 0 1 .546 -.124z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M6.5 12h14.5', key: 'svg-1' }],
]);
