import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'sort-ascending-shapes',
  'IconSortAscendingShapes',
  [
    ['path', { d: 'M4 15l3 3l3 -3', key: 'svg-0' }],
    ['path', { d: 'M7 6v12', key: 'svg-1' }],
    [
      'path',
      {
        d: 'M14 5a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1v-4z',
        key: 'svg-2',
      },
    ],
    ['path', { d: 'M17 14l-3.5 6h7z', key: 'svg-3' }],
  ],
);
