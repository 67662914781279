import createReactComponent from '../createReactComponent';
export default createReactComponent('skew-y', 'IconSkewY', [
  [
    'path',
    {
      d: 'M4.326 19h15.348a1 1 0 0 0 .962 -1.275l-3.429 -12a1 1 0 0 0 -.961 -.725h-8.492a1 1 0 0 0 -.961 .725l-3.429 12a1 1 0 0 0 .962 1.275z',
      key: 'svg-0',
    },
  ],
]);
