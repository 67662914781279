import createReactComponent from '../createReactComponent';
export default createReactComponent('burger', 'IconBurger', [
  ['path', { d: 'M4 15h16a4 4 0 0 1 -4 4h-8a4 4 0 0 1 -4 -4z', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M12 4c3.783 0 6.953 2.133 7.786 5h-15.572c.833 -2.867 4.003 -5 7.786 -5z',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M5 12h14', key: 'svg-2' }],
]);
