import createReactComponent from '../createReactComponent';
export default createReactComponent('tags-off', 'IconTagsOff', [
  ['path', { d: 'M16.296 12.296l-5.71 -5.71', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M6 6h-1a2 2 0 0 0 -2 2v4.172a2 2 0 0 0 .586 1.414l5.71 5.71a2.41 2.41 0 0 0 3.408 0l3.278 -3.278',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M18 19l.496 -.496', key: 'svg-2' }],
  [
    'path',
    {
      d: 'M20.384 16.367a4.822 4.822 0 0 0 -.792 -5.775l-4.592 -4.592',
      key: 'svg-3',
    },
  ],
  ['path', { d: 'M7 10h-.01', key: 'svg-4' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-5' }],
]);
