import createReactComponent from '../createReactComponent';
export default createReactComponent('brand-revolut', 'IconBrandRevolut', [
  ['path', { d: 'M5 10h3v10h-3z', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M14.5 4h-9.5v3h9.4a1.5 1.5 0 0 1 0 3h-3.4v4l4 6h4l-5 -7h.5a4.5 4.5 0 1 0 0 -9z',
      key: 'svg-1',
    },
  ],
]);
