import createReactComponent from '../createReactComponent';
export default createReactComponent('wheel', 'IconWheel', [
  ['path', { d: 'M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0', key: 'svg-0' }],
  ['path', { d: 'M12 12m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0', key: 'svg-1' }],
  ['path', { d: 'M3 12h6', key: 'svg-2' }],
  ['path', { d: 'M15 12h6', key: 'svg-3' }],
  ['path', { d: 'M13.6 9.4l3.4 -4.8', key: 'svg-4' }],
  ['path', { d: 'M10.4 14.6l-3.4 4.8', key: 'svg-5' }],
  ['path', { d: 'M7 4.6l3.4 4.8', key: 'svg-6' }],
  ['path', { d: 'M13.6 14.6l3.4 4.8', key: 'svg-7' }],
]);
