import createReactComponent from '../createReactComponent';
export default createReactComponent('stack-backward', 'IconStackBackward', [
  ['path', { d: 'M14 12l6 -3l-8 -4l-8 4l6 3', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M10 12l-6 3l8 4l8 -4l-6 -3l-2 1z',
      fill: 'currentColor',
      key: 'svg-1',
    },
  ],
]);
