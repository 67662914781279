import createReactComponent from '../createReactComponent';
export default createReactComponent('number-18-small', 'IconNumber18Small', [
  ['path', { d: 'M7 10l2 -2v8', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M15 12h-1a1 1 0 0 1 -1 -1v-2a1 1 0 0 1 1 -1h2a1 1 0 0 1 1 1v2a1 1 0 0 1 -1 1h-2a1 1 0 0 0 -1 1v2a1 1 0 0 0 1 1h2a1 1 0 0 0 1 -1v-2a1 1 0 0 0 -1 -1',
      key: 'svg-1',
    },
  ],
]);
