import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'layout-bottombar-inactive',
  'IconLayoutBottombarInactive',
  [
    [
      'path',
      {
        d: 'M4 6a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2v-12z',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M4 15h1', key: 'svg-1' }],
    ['path', { d: 'M19 15h1', key: 'svg-2' }],
    ['path', { d: 'M9 15h1', key: 'svg-3' }],
    ['path', { d: 'M14 15h1', key: 'svg-4' }],
  ],
);
