import createReactComponent from '../createReactComponent';
export default createReactComponent('credit-card-pay', 'IconCreditCardPay', [
  [
    'path',
    {
      d: 'M12 19h-6a3 3 0 0 1 -3 -3v-8a3 3 0 0 1 3 -3h12a3 3 0 0 1 3 3v4.5',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M3 10h18', key: 'svg-1' }],
  ['path', { d: 'M16 19h6', key: 'svg-2' }],
  ['path', { d: 'M19 16l3 3l-3 3', key: 'svg-3' }],
  ['path', { d: 'M7.005 15h.005', key: 'svg-4' }],
  ['path', { d: 'M11 15h2', key: 'svg-5' }],
]);
