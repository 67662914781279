import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'alert-square-rounded-off',
  'IconAlertSquareRoundedOff',
  [
    [
      'path',
      {
        d: 'M19.201 19.199c-1.35 1.35 -3.6 1.801 -7.201 1.801c-7.2 0 -9 -1.8 -9 -9c0 -3.598 .45 -5.847 1.797 -7.197m2.626 -1.376c1.204 -.307 2.709 -.427 4.577 -.427c7.2 0 9 1.8 9 9c0 1.865 -.12 3.367 -.425 4.57',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M12 7v1', key: 'svg-1' }],
    ['path', { d: 'M12 16h.01', key: 'svg-2' }],
    ['path', { d: 'M3 3l18 18', key: 'svg-3' }],
  ],
);
