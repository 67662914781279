import createReactComponent from '../createReactComponent';
export default createReactComponent('brand-pnpm', 'IconBrandPnpm', [
  ['path', { d: 'M3 17h4v4h-4z', key: 'svg-0' }],
  ['path', { d: 'M10 17h4v4h-4z', key: 'svg-1' }],
  ['path', { d: 'M17 17h4v4h-4z', key: 'svg-2' }],
  ['path', { d: 'M17 10h4v4h-4z', key: 'svg-3' }],
  ['path', { d: 'M17 3h4v4h-4z', key: 'svg-4' }],
  ['path', { d: 'M10 10h4v4h-4z', key: 'svg-5' }],
  ['path', { d: 'M10 3h4v4h-4z', key: 'svg-6' }],
  ['path', { d: 'M3 3h4v4h-4z', key: 'svg-7' }],
]);
