import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'triangle-inverted',
  'IconTriangleInverted',
  [
    [
      'path',
      {
        d: 'M10.363 20.405l-8.106 -13.534a1.914 1.914 0 0 1 1.636 -2.871h16.214a1.914 1.914 0 0 1 1.636 2.871l-8.106 13.534a1.914 1.914 0 0 1 -3.274 0z',
        key: 'svg-0',
      },
    ],
  ],
);
