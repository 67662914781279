import createReactComponent from '../createReactComponent';
export default createReactComponent('number-3-small', 'IconNumber3Small', [
  [
    'path',
    {
      d: 'M10 8h2.5a1.5 1.5 0 0 1 1.5 1.5v1a1.5 1.5 0 0 1 -1.5 1.5h-1.5h1.5a1.5 1.5 0 0 1 1.5 1.5v1a1.5 1.5 0 0 1 -1.5 1.5h-2.5',
      key: 'svg-0',
    },
  ],
]);
