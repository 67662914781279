import createReactComponent from '../createReactComponent';
export default createReactComponent('input-check', 'IconInputCheck', [
  [
    'path',
    {
      d: 'M20 13v-4a2 2 0 0 0 -2 -2h-12a2 2 0 0 0 -2 2v5a2 2 0 0 0 2 2h6',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M15 19l2 2l4 -4', key: 'svg-1' }],
]);
