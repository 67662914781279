import createReactComponent from '../createReactComponent';
export default createReactComponent('sandbox', 'IconSandbox', [
  [
    'path',
    {
      d: 'M19.953 8.017l1.047 6.983v2a3 3 0 0 1 -3 3h-12a3 3 0 0 1 -3 -3v-2l1.245 -8.297a2 2 0 0 1 1.977 -1.703h3.778',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M3 15h18', key: 'svg-1' }],
  ['path', { d: 'M13 3l5.5 1.5', key: 'svg-2' }],
  ['path', { d: 'M15.75 3.75l-2 7', key: 'svg-3' }],
  [
    'path',
    {
      d: 'M7 10.5c1.667 -.667 3.333 -.667 5 0c1.667 .667 3.333 .667 5 0',
      key: 'svg-4',
    },
  ],
]);
