import createReactComponent from '../createReactComponent';
export default createReactComponent('grid-scan', 'IconGridScan', [
  ['path', { d: 'M10 8v8', key: 'svg-0' }],
  ['path', { d: 'M14 8v8', key: 'svg-1' }],
  ['path', { d: 'M8 10h8', key: 'svg-2' }],
  ['path', { d: 'M8 14h8', key: 'svg-3' }],
  ['path', { d: 'M4 8v-2a2 2 0 0 1 2 -2h2', key: 'svg-4' }],
  ['path', { d: 'M4 16v2a2 2 0 0 0 2 2h2', key: 'svg-5' }],
  ['path', { d: 'M16 4h2a2 2 0 0 1 2 2v2', key: 'svg-6' }],
  ['path', { d: 'M16 20h2a2 2 0 0 0 2 -2v-2', key: 'svg-7' }],
]);
