import createReactComponent from '../createReactComponent';
export default createReactComponent('bookmarks', 'IconBookmarks', [
  [
    'path',
    {
      d: 'M15 10v11l-5 -3l-5 3v-11a3 3 0 0 1 3 -3h4a3 3 0 0 1 3 3z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M11 3h5a3 3 0 0 1 3 3v11', key: 'svg-1' }],
]);
