import createReactComponent from '../createReactComponent';
export default createReactComponent('brand-patreon', 'IconBrandPatreon', [
  [
    'path',
    {
      d: 'M20 8.408c-.003 -2.299 -1.746 -4.182 -3.79 -4.862c-2.54 -.844 -5.888 -.722 -8.312 .453c-2.939 1.425 -3.862 4.545 -3.896 7.656c-.028 2.559 .22 9.297 3.92 9.345c2.75 .036 3.159 -3.603 4.43 -5.356c.906 -1.247 2.071 -1.599 3.506 -1.963c2.465 -.627 4.146 -2.626 4.142 -5.273z',
      key: 'svg-0',
    },
  ],
]);
