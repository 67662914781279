import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'transaction-bitcoin',
  'IconTransactionBitcoin',
  [
    [
      'path',
      {
        d: 'M15 12h4.09c1.055 0 1.91 .895 1.91 2s-.855 2 -1.91 2c1.055 0 1.91 .895 1.91 2s-.855 2 -1.91 2h-4.09',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M16 16h4', key: 'svg-1' }],
    ['path', { d: 'M16 11v10v-9', key: 'svg-2' }],
    ['path', { d: 'M19 11v1', key: 'svg-3' }],
    ['path', { d: 'M19 20v1', key: 'svg-4' }],
    ['path', { d: 'M5 5m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-5' }],
    ['path', { d: 'M17 5m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-6' }],
    ['path', { d: 'M7 5h8', key: 'svg-7' }],
    ['path', { d: 'M7 5v8a3 3 0 0 0 3 3h1', key: 'svg-8' }],
  ],
);
