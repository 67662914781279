import createReactComponent from '../createReactComponent';
export default createReactComponent('text-grammar', 'IconTextGrammar', [
  ['path', { d: 'M14 9a3 3 0 1 0 6 0a3 3 0 0 0 -6 0', key: 'svg-0' }],
  ['path', { d: 'M4 12v-5a3 3 0 1 1 6 0v5', key: 'svg-1' }],
  ['path', { d: 'M4 9h6', key: 'svg-2' }],
  ['path', { d: 'M20 6v6', key: 'svg-3' }],
  ['path', { d: 'M4 16h12', key: 'svg-4' }],
  ['path', { d: 'M4 20h6', key: 'svg-5' }],
  ['path', { d: 'M14 20l2 2l5 -5', key: 'svg-6' }],
]);
