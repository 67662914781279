import createReactComponent from '../createReactComponent';
export default createReactComponent('number-20-small', 'IconNumber20Small', [
  ['path', { d: 'M14 10v4a2 2 0 1 0 4 0v-4a2 2 0 1 0 -4 0z', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M6 8h3a1 1 0 0 1 1 1v2a1 1 0 0 1 -1 1h-2a1 1 0 0 0 -1 1v2a1 1 0 0 0 1 1h3',
      key: 'svg-1',
    },
  ],
]);
