import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'chart-dots-filled',
  'IconChartDotsFilled',
  [
    [
      'path',
      {
        d: 'M3 2a1 1 0 0 1 1 1v17h17a1 1 0 0 1 .993 .883l.007 .117a1 1 0 0 1 -1 1h-18a1 1 0 0 1 -1 -1v-18a1 1 0 0 1 1 -1z',
        fill: 'currentColor',
        key: 'svg-0',
        strokeWidth: '0',
      },
    ],
    [
      'path',
      {
        d: 'M19 4a3 3 0 1 1 -.651 5.93l-2.002 3.202a3 3 0 1 1 -4.927 .337l-1.378 -1.655a3 3 0 1 1 1.538 -1.282l1.378 1.654a2.994 2.994 0 0 1 1.693 -.115l2.002 -3.203a3 3 0 0 1 2.347 -4.868z',
        fill: 'currentColor',
        key: 'svg-1',
        strokeWidth: '0',
      },
    ],
  ],
);
