import createReactComponent from '../createReactComponent';
export default createReactComponent('route-x', 'IconRouteX', [
  ['path', { d: 'M3 17l4 4', key: 'svg-0' }],
  ['path', { d: 'M7 17l-4 4', key: 'svg-1' }],
  ['path', { d: 'M17 3l4 4', key: 'svg-2' }],
  ['path', { d: 'M21 3l-4 4', key: 'svg-3' }],
  [
    'path',
    {
      d: 'M11 19h5.5a3.5 3.5 0 0 0 0 -7h-8a3.5 3.5 0 0 1 0 -7h4.5',
      key: 'svg-4',
    },
  ],
]);
