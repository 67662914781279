import createReactComponent from '../createReactComponent';
export default createReactComponent('hospital', 'IconHospital', [
  [
    'path',
    {
      d: 'M3 5a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v14a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-14z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M10 16v-8', key: 'svg-1' }],
  ['path', { d: 'M14 16v-8', key: 'svg-2' }],
  ['path', { d: 'M10 12h4', key: 'svg-3' }],
]);
