import createReactComponent from '../createReactComponent';
export default createReactComponent('library-photo', 'IconLibraryPhoto', [
  [
    'path',
    {
      d: 'M7 3m0 2.667a2.667 2.667 0 0 1 2.667 -2.667h8.666a2.667 2.667 0 0 1 2.667 2.667v8.666a2.667 2.667 0 0 1 -2.667 2.667h-8.666a2.667 2.667 0 0 1 -2.667 -2.667z',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M4.012 7.26a2.005 2.005 0 0 0 -1.012 1.737v10c0 1.1 .9 2 2 2h10c.75 0 1.158 -.385 1.5 -1',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M17 7h.01', key: 'svg-2' }],
  [
    'path',
    {
      d: 'M7 13l3.644 -3.644a1.21 1.21 0 0 1 1.712 0l3.644 3.644',
      key: 'svg-3',
    },
  ],
  [
    'path',
    {
      d: 'M15 12l1.644 -1.644a1.21 1.21 0 0 1 1.712 0l2.644 2.644',
      key: 'svg-4',
    },
  ],
]);
