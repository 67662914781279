import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'photo-circle-plus',
  'IconPhotoCirclePlus',
  [
    ['path', { d: 'M15 8h.01', key: 'svg-0' }],
    [
      'path',
      {
        d: 'M20.964 12.806a9 9 0 0 0 -8.964 -9.806a9 9 0 0 0 -9 9a9 9 0 0 0 9.397 8.991',
        key: 'svg-1',
      },
    ],
    ['path', { d: 'M4 15l4 -4c.928 -.893 2.072 -.893 3 0l4 4', key: 'svg-2' }],
    ['path', { d: 'M14 14l1 -1c.928 -.893 2.072 -.893 3 0', key: 'svg-3' }],
    ['path', { d: 'M16 19.33h6', key: 'svg-4' }],
    ['path', { d: 'M19 16.33v6', key: 'svg-5' }],
  ],
);
