import createReactComponent from '../createReactComponent';
export default createReactComponent('help-triangle', 'IconHelpTriangle', [
  ['path', { d: 'M12 16v.01', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M10.363 3.591l-8.106 13.534a1.914 1.914 0 0 0 1.636 2.871h16.214a1.914 1.914 0 0 0 1.636 -2.87l-8.106 -13.536a1.914 1.914 0 0 0 -3.274 0z',
      key: 'svg-1',
    },
  ],
  [
    'path',
    {
      d: 'M12 13a2 2 0 0 0 .914 -3.782a1.98 1.98 0 0 0 -2.414 .483',
      key: 'svg-2',
    },
  ],
]);
