import createReactComponent from '../createReactComponent';
export default createReactComponent('blender', 'IconBlender', [
  [
    'path',
    {
      d: 'M9 10h-3a1 1 0 0 1 -1 -1v-4a1 1 0 0 1 1 -1h10.802a1 1 0 0 1 .984 1.179l-1.786 9.821',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M8 4l2 11', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M11 15h4a3 3 0 0 1 3 3v2a1 1 0 0 1 -1 1h-8a1 1 0 0 1 -1 -1v-2a3 3 0 0 1 3 -3z',
      key: 'svg-2',
    },
  ],
  ['path', { d: 'M12 4v-1h2v1', key: 'svg-3' }],
  ['path', { d: 'M13 18v.01', key: 'svg-4' }],
]);
