import createReactComponent from '../createReactComponent';
export default createReactComponent('clock-24', 'IconClock24', [
  [
    'path',
    {
      d: 'M3 12a9 9 0 0 0 5.998 8.485m12.002 -8.485a9 9 0 1 0 -18 0',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M12 7v5', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M12 15h2a1 1 0 0 1 1 1v1a1 1 0 0 1 -1 1h-1a1 1 0 0 0 -1 1v1a1 1 0 0 0 1 1h2',
      key: 'svg-2',
    },
  ],
  ['path', { d: 'M18 15v2a1 1 0 0 0 1 1h1', key: 'svg-3' }],
  ['path', { d: 'M21 15v6', key: 'svg-4' }],
]);
